import React from "react";
import { Link } from "@inertiajs/react"; // Updated Link import

const NotFound = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex flex-col items-center justify-center">
            <div className="text-center">
                <h1 className="text-9xl font-extrabold text-gray-100 tracking-widest">
                    404
                </h1>
                <div
                    className="bg-gray-700 px-2 text-sm text-center text-gray-200 rounded rotate-6 absolute transform translate-y-12 shadow-lg ml-20 mr-20"
                    style={{ top: "410px" }}
                >
                    <p className="text-center">Page Not Found</p>
                </div>
            </div>
            <div className="mt-5 text-center">
                <p className="text-gray-300 mt-9 text-xl md:text-2xl font-light mb-8">
                    It seems we can’t find the page you’re looking for.
                </p>
                <Link
                    href="/"
                    className="relative inline-block text-sm font-medium text-gray-900 group focus:outline-none focus:ring"
                >
                    <span className="absolute inset-0 transform bg-gray-400 transition-transform"></span>
                    <span className="relative block px-8 py-3 bg-gray-800 border border-current text-gray-100 rounded-lg hover:bg-gray-700">
                        Return to Homepage
                    </span>
                </Link>
            </div>
            <div className="absolute bottom-10 text-gray-400 opacity-60">
                <p>© 2024 Central Event Hire Ltd.</p>
            </div>
        </div>
    );
};

export default NotFound;
